import { track_banner_clicked, track_banner_displayed } from './events_tracker.js';
import $ from 'jquery'; 

function banner_clicked(e) {
  let $banner = $(e.currentTarget);
  //console.log("BANNER TRACKER -> click", e);
  if (!$banner.data('click_tracked')) {
    let campaign = $banner.data("novychas-banner-campaign");
    let creative = $banner.data("novychas-banner-creative");
    track_banner_clicked(campaign, creative);
    $banner.data('click_tracked', true);
  }
}

function banner_viewed(e) {
  //console.log("BANNER TRACKER -> view", e);
  let $banner = $(e.currentTarget);
  if (!$banner.data('view_tracked')) {
    let campaign = $banner.data("novychas-banner-campaign");
    let creative = $banner.data("novychas-banner-creative");
    track_banner_displayed(campaign, creative);
    $banner.data("view_tracked", true);
  } 
}

export default function banners_tracker_init() {
  //console.log("BANNER_TRACKER -> init");
  let $click_trackable = $('[data-novychas-banner-track*="click"]');
  let $view_trackable  = $('[data-novychas-banner-track*="view"]');
  //console.log("BANNER_TRACKER -> init clicks tracking", $click_trackable);
  $click_trackable.on('click', banner_clicked);
  //console.log("BANNER_TRACKER -> init views tracking", $view_trackable);
  $view_trackable.each(function(i,element) {
    let $banner = $(element);
    //console.log("BANNER_TRACKER -> init viewable", $banner);
    let campaign = $banner.data("novychas-banner-campaign");
    let creative = $banner.data("novychas-banner-creative");
    $banner.one('load',banner_viewed);
    try {
      let $img = $banner.find('img');
      let img = $img.get()[0];
      //console.log("BANNER_TRACKER -> found img", img);
      //console.log("BANNER_TRACKER -> img.complete", img.complete);
      if (img && img.complete) {
        //console.log("BANNER_TRACKER -> trigger load");
        $banner.trigger('load');
      } else if ($banner.hasClass("pagewide-banner")) {
        $banner.trigger('load');
      }
    } catch (e) {
      console.log("Failed to set view tracker", e);
    }
  });
}

